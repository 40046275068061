const beeful = [
    {
        "variations": [
            {
                "id": 701,
                "color": "BLOSSOM",
                "size": "2XL",
                "sku": "UT-1717-BLM-2XL"
            },
            {
                "id": 697,
                "color": "BLOSSOM",
                "size": "S",
                "sku": "UT-1717-BLM-S"
            },
            {
                "id": 681,
                "color": "LIGHT GREEN",
                "size": "2XL",
                "sku": "UT-1717-LIN-2XL"
            },
            {
                "id": 687,
                "color": "BLUE JEAN",
                "size": "S",
                "sku": "UT-1717-BLN-S"
            },
            {
                "id": 688,
                "color": "BLUE JEAN",
                "size": "M",
                "sku": "UT-1717-BLN-M"
            },
            {
                "id": 692,
                "color": "YAM",
                "size": "S",
                "sku": "UT-1717-YAM-S"
            },
            {
                "id": 700,
                "color": "BLOSSOM",
                "size": "XL",
                "sku": "UT-1717-BLM-XL"
            },
            {
                "id": 699,
                "color": "BLOSSOM",
                "size": "L",
                "sku": "UT-1717-BLM-L"
            },
            {
                "id": 698,
                "color": "BLOSSOM",
                "size": "M",
                "sku": "UT-1717-BLM-M"
            },
            {
                "id": 690,
                "color": "BLUE JEAN",
                "size": "XL",
                "sku": "UT-1717-BLN-XL"
            },
            {
                "id": 677,
                "color": "LIGHT GREEN",
                "size": "S",
                "sku": "UT-1717-LIN-S"
            },
            {
                "id": 419,
                "color": "NAVY",
                "size": "M",
                "sku": "UT-1717-NAY-M"
            },
            {
                "id": 418,
                "color": "NAVY",
                "size": "S",
                "sku": "UT-1717-NAY-S"
            },
            {
                "id": 684,
                "color": "GRAPHITE",
                "size": "L",
                "sku": "UT-1717-GRE-L"
            },
            {
                "id": 682,
                "color": "GRAPHITE",
                "size": "S",
                "sku": "UT-1717-GRE-S"
            },
            {
                "id": 417,
                "color": "ORCHID",
                "size": "S",
                "sku": "UT-1717-ORD-S"
            },
            {
                "id": 420,
                "color": "NAVY",
                "size": "2XL",
                "sku": "UT-1717-NAY-2XL"
            },
            {
                "id": 689,
                "color": "BLUE JEAN",
                "size": "L",
                "sku": "UT-1717-BLN-L"
            },
            {
                "id": 685,
                "color": "GRAPHITE",
                "size": "XL",
                "sku": "UT-1717-GRE-XL"
            },
            {
                "id": 683,
                "color": "GRAPHITE",
                "size": "M",
                "sku": "UT-1717-GRE-M"
            },
            {
                "id": 703,
                "color": "MUSTARD",
                "size": "M",
                "sku": "UT-1717-MUD-M"
            },
            {
                "id": 704,
                "color": "MUSTARD",
                "size": "L",
                "sku": "UT-1717-MUD-L"
            },
            {
                "id": 667,
                "color": "WHITE",
                "size": "L",
                "sku": "UT-1717-WHE-L"
            },
            {
                "id": 655,
                "color": "PEPPER",
                "size": "3XL",
                "sku": "UT-1717-PER-3XL"
            },
            {
                "id": 705,
                "color": "MUSTARD",
                "size": "XL",
                "sku": "UT-1717-MUD-XL"
            },
            {
                "id": 661,
                "color": "IVORY",
                "size": "3XL",
                "sku": "UT-1717-IVY-3XL"
            },
            {
                "id": 706,
                "color": "MUSTARD",
                "size": "2XL",
                "sku": "UT-1717-MUD-2XL"
            },
            {
                "id": 702,
                "color": "MUSTARD",
                "size": "S",
                "sku": "UT-1717-MUD-S"
            },
            {
                "id": 670,
                "color": "MOSS",
                "size": "S",
                "sku": "UT-1717-MOS-S"
            },
            {
                "id": 656,
                "color": "IVORY",
                "size": "S",
                "sku": "UT-1717-IVY-S"
            },
            {
                "id": 678,
                "color": "LIGHT GREEN",
                "size": "M",
                "sku": "UT-1717-LIN-M"
            },
            {
                "id": 662,
                "color": "ORCHID",
                "size": "M",
                "sku": "UT-1717-ORD-M"
            },
            {
                "id": 680,
                "color": "LIGHT GREEN",
                "size": "XL",
                "sku": "UT-1717-LIN-XL"
            },
            {
                "id": 669,
                "color": "WHITE",
                "size": "2XL",
                "sku": "UT-1717-WHE-2XL"
            },
            {
                "id": 679,
                "color": "LIGHT GREEN",
                "size": "L",
                "sku": "UT-1717-LIN-L"
            },
            {
                "id": 675,
                "color": "NAVY",
                "size": "L",
                "sku": "UT-1717-NAY-L"
            },
            {
                "id": 686,
                "color": "GRAPHITE",
                "size": "2XL",
                "sku": "UT-1717-GRE-2XL"
            },
            {
                "id": 672,
                "color": "MOSS",
                "size": "L",
                "sku": "UT-1717-MOS-L"
            },
            {
                "id": 665,
                "color": "ORCHID",
                "size": "2XL",
                "sku": "UT-1717-ORD-2XL"
            },
            {
                "id": 649,
                "color": "BLACK",
                "size": "3XL",
                "sku": "UT-1717-BLK-3XL"
            },
            {
                "id": 663,
                "color": "ORCHID",
                "size": "L",
                "sku": "UT-1717-ORD-L"
            },
            {
                "id": 660,
                "color": "IVORY",
                "size": "2XL",
                "sku": "UT-1717-IVY-2XL"
            },
            {
                "id": 650,
                "color": "PEPPER",
                "size": "S",
                "sku": "UT-1717-PER-S"
            },
            {
                "id": 691,
                "color": "BLUE JEAN",
                "size": "2XL",
                "sku": "UT-1717-BLN-2XL"
            },
            {
                "id": 659,
                "color": "IVORY",
                "size": "XL",
                "sku": "UT-1717-IVY-XL"
            },
            {
                "id": 676,
                "color": "NAVY",
                "size": "XL",
                "sku": "UT-1717-NAY-XL"
            },
            {
                "id": 696,
                "color": "YAM",
                "size": "2XL",
                "sku": "UT-1717-YAM-2XL"
            },
            {
                "id": 673,
                "color": "MOSS",
                "size": "XL",
                "sku": "UT-1717-MOS-XL"
            },
            {
                "id": 804,
                "color": "WHITE",
                "size": "S",
                "sku": "UT-1717-WHE-S"
            },
            {
                "id": 654,
                "color": "PEPPER",
                "size": "2XL",
                "sku": "UT-1717-PER-2XL"
            },
            {
                "id": 694,
                "color": "YAM",
                "size": "L",
                "sku": "UT-1717-YAM-L"
            },
            {
                "id": 693,
                "color": "YAM",
                "size": "M",
                "sku": "UT-1717-YAM-M"
            },
            {
                "id": 644,
                "color": "BLACK",
                "size": "S",
                "sku": "UT-1717-BLK-S"
            },
            {
                "id": 646,
                "color": "BLACK",
                "size": "L",
                "sku": "UT-1717-BLK-L"
            },
            {
                "id": 664,
                "color": "ORCHID",
                "size": "XL",
                "sku": "UT-1717-ORD-XL"
            },
            {
                "id": 648,
                "color": "BLACK",
                "size": "2XL",
                "sku": "UT-1717-BLK-2XL"
            },
            {
                "id": 666,
                "color": "WHITE",
                "size": "M",
                "sku": "UT-1717-WHE-M"
            },
            {
                "id": 658,
                "color": "IVORY",
                "size": "L",
                "sku": "UT-1717-IVY-L"
            },
            {
                "id": 668,
                "color": "WHITE",
                "size": "XL",
                "sku": "UT-1717-WHE-XL"
            },
            {
                "id": 657,
                "color": "IVORY",
                "size": "M",
                "sku": "UT-1717-IVY-M"
            },
            {
                "id": 651,
                "color": "PEPPER",
                "size": "M",
                "sku": "UT-1717-PER-M"
            },
            {
                "id": 674,
                "color": "MOSS",
                "size": "2XL",
                "sku": "UT-1717-MOS-2XL"
            },
            {
                "id": 671,
                "color": "MOSS",
                "size": "M",
                "sku": "UT-1717-MOS-M"
            },
            {
                "id": 653,
                "color": "PEPPER",
                "size": "XL",
                "sku": "UT-1717-PER-XL"
            },
            {
                "id": 695,
                "color": "YAM",
                "size": "XL",
                "sku": "UT-1717-YAM-XL"
            },
            {
                "id": 645,
                "color": "BLACK",
                "size": "M",
                "sku": "UT-1717-BLK-M"
            },
            {
                "id": 652,
                "color": "PEPPER",
                "size": "L",
                "sku": "UT-1717-PER-L"
            },
            {
                "id": 647,
                "color": "BLACK",
                "size": "XL",
                "sku": "UT-1717-BLK-XL"
            }
        ],
        "name": "Unisex-ComfortColor-UnisexT-Shirt",
        "sku": "1717"
    },
    {
        "variations": [
            {
                "id": 734,
                "color": "HEATHER MAUVE",
                "size": "M",
                "sku": "UT-3001-HEE-M"
            },
            {
                "id": 805,
                "color": "DARK GREY HEATHER",
                "size": "M",
                "sku": "UT-3001-DAR-M"
            },
            {
                "id": 728,
                "color": "HEATHER NAVY",
                "size": "S",
                "sku": "UT-3001-HEY-S"
            },
            {
                "id": 732,
                "color": "HEATHER NAVY",
                "size": "2XL",
                "sku": "UT-3001-HEY-2XL"
            },
            {
                "id": 730,
                "color": "HEATHER NAVY",
                "size": "L",
                "sku": "UT-3001-HEY-L"
            },
            {
                "id": 741,
                "color": "ATHLETIC HEATHER",
                "size": "XL",
                "sku": "UT-3001-ATR-XL"
            },
            {
                "id": 739,
                "color": "ATHLETIC HEATHER",
                "size": "M",
                "sku": "UT-3001-ATR-M"
            },
            {
                "id": 729,
                "color": "Heather Navy",
                "size": "M",
                "sku": "UT-3001-HEY-M"
            },
            {
                "id": 735,
                "color": "HEATHER MAUVE",
                "size": "L",
                "sku": "UT-3001-HEE-L"
            },
            {
                "id": 731,
                "color": "HEATHER NAVY",
                "size": "XL",
                "sku": "UT-3001-HEY-XL"
            },
            {
                "id": 733,
                "color": "HEATHER MAUVE",
                "size": "S",
                "sku": "UT-3001-HEE-S"
            },
            {
                "id": 740,
                "color": "ATHLETIC HEATHER",
                "size": "L",
                "sku": "UT-3001-ATR-L"
            },
            {
                "id": 736,
                "color": "HEATHER MAUVE",
                "size": "XL",
                "sku": "UT-3001-HEE-XL"
            },
            {
                "id": 737,
                "color": "HEATHER MAUVE",
                "size": "2XL",
                "sku": "UT-3001-HEE-2XL"
            },
            {
                "id": 738,
                "color": "ATHLETIC HEATHER",
                "size": "S",
                "sku": "UT-3001-ATR-S"
            },
            {
                "id": 743,
                "color": "BLACK",
                "size": "2XL",
                "sku": "UT-3001-BLK-2XL"
            },
            {
                "id": 742,
                "color": "ATHLETIC HEATHER",
                "size": "2XL",
                "sku": "UT-3001-ATR-2XL"
            },
            {
                "id": 723,
                "color": "NATURAL",
                "size": "2XL",
                "sku": "UT-3001-NAL-2XL"
            },
            {
                "id": 725,
                "color": "DARK GREY HEATHER",
                "size": "L",
                "sku": "UT-3001-DAR-L"
            },
            {
                "id": 719,
                "color": "NATURAL",
                "size": "S",
                "sku": "UT-3001-NAL-S"
            },
            {
                "id": 711,
                "color": "NAVY",
                "size": "S",
                "sku": "UT-3001-NAY-S"
            },
            {
                "id": 724,
                "color": "DARK GREY HEATHER",
                "size": "S",
                "sku": "UT-3001-DAR-S"
            },
            {
                "id": 707,
                "color": "BLACK",
                "size": "S",
                "sku": "UT-3001-BLK-S"
            },
            {
                "id": 727,
                "color": "DARK GREY HEATHER",
                "size": "2XL",
                "sku": "UT-3001-DAR-2XL"
            },
            {
                "id": 744,
                "color": "WHITE",
                "size": "2XL",
                "sku": "UT-3001-WHE-2XL"
            },
            {
                "id": 722,
                "color": "NATURAL",
                "size": "XL",
                "sku": "UT-3001-NAL-XL"
            },
            {
                "id": 712,
                "color": "WHITE",
                "size": "S",
                "sku": "UT-3001-WHE-S"
            },
            {
                "id": 717,
                "color": "NAVY",
                "size": "XL",
                "sku": "UT-3001-NAY-XL"
            },
            {
                "id": 714,
                "color": "NAVY",
                "size": "L",
                "sku": "UT-3001-NAY-L"
            },
            {
                "id": 745,
                "color": "NAVY",
                "size": "2XL",
                "sku": "UT-3001-NAY-2XL"
            },
            {
                "id": 709,
                "color": "NAVY",
                "size": "M",
                "sku": "UT-3001-NAY-M"
            },
            {
                "id": 721,
                "color": "NATURAL",
                "size": "L",
                "sku": "UT-3001-NAL-L"
            },
            {
                "id": 713,
                "color": "BLACK",
                "size": "L",
                "sku": "UT-3001-BLK-L"
            },
            {
                "id": 710,
                "color": "WHITE",
                "size": "M",
                "sku": "UT-3001-WHE-M"
            },
            {
                "id": 726,
                "color": "DARK GREY HEATHER",
                "size": "XL",
                "sku": "UT-3001-DAR-XL"
            },
            {
                "id": 715,
                "color": "WHITE",
                "size": "L",
                "sku": "UT-3001-WHE-L"
            },
            {
                "id": 720,
                "color": "NATURAL",
                "size": "M",
                "sku": "UT-3001-NAL-M"
            },
            {
                "id": 716,
                "color": "BLACK",
                "size": "XL",
                "sku": "UT-3001-BLK-XL"
            },
            {
                "id": 708,
                "color": "BLACK",
                "size": "M",
                "sku": "UT-3001-BLK-M"
            },
            {
                "id": 718,
                "color": "WHITE",
                "size": "XL",
                "sku": "UT-3001-WHE-XL"
            }
        ],
        "name": "Unisex-BellaCanvas-UnisexT-Shirt",
        "sku": "3001"
    },
    {
        "variations": [
            {
                "id": 493,
                "color": "MAROON",
                "size": "3XL",
                "sku": "UT-5000-MAN-3XL"
            },
            {
                "id": 459,
                "color": "RED",
                "size": "3XL",
                "sku": "UT-5000-RED-3XL"
            },
            {
                "id": 488,
                "color": "MAROON",
                "size": "S",
                "sku": "UT-5000-MAN-S"
            },
            {
                "id": 492,
                "color": "MAROON",
                "size": "2XL",
                "sku": "UT-5000-MAN-2XL"
            },
            {
                "id": 500,
                "color": "ASH",
                "size": "3XL",
                "sku": "UT-5000-ASH-3XL"
            },
            {
                "id": 442,
                "color": "MILITARY GREEN",
                "size": "3XL",
                "sku": "UT-5000-MIN-3XL"
            },
            {
                "id": 421,
                "color": "PURPLE",
                "size": "S",
                "sku": "UT-5000-PUE-S"
            },
            {
                "id": 502,
                "color": "WHITE",
                "size": "4XL",
                "sku": "UT-5000-WHE-4XL"
            },
            {
                "id": 453,
                "color": "PURPLE",
                "size": "3XL",
                "sku": "UT-5000-PUE-3XL"
            },
            {
                "id": 554,
                "color": "NATURAL",
                "size": "2XL",
                "sku": "UT-5000-NAL-2XL"
            },
            {
                "id": 530,
                "color": "WHITE",
                "size": "5XL",
                "sku": "UT-5000-WHE-5XL"
            },
            {
                "id": 555,
                "color": "ORANGE",
                "size": "2XL",
                "sku": "UT-5000-ORE-2XL"
            },
            {
                "id": 546,
                "color": "HELICONIA",
                "size": "XL",
                "sku": "UT-5000-HEA-XL"
            },
            {
                "id": 511,
                "color": "DARK CHOCOLATE",
                "size": "L",
                "sku": "UT-5000-DAE-L"
            },
            {
                "id": 541,
                "color": "DAISY",
                "size": "2XL",
                "sku": "UT-5000-DAY-2XL"
            },
            {
                "id": 514,
                "color": "DARK CHOCOLATE",
                "size": "XL",
                "sku": "UT-5000-DAE-XL"
            },
            {
                "id": 497,
                "color": "SAND",
                "size": "XL",
                "sku": "UT-5000-SAD-XL"
            },
            {
                "id": 545,
                "color": "HELICONIA",
                "size": "L",
                "sku": "UT-5000-HEA-L"
            },
            {
                "id": 506,
                "color": "DARK CHOCOLATE",
                "size": "M",
                "sku": "UT-5000-DAE-M"
            },
            {
                "id": 519,
                "color": "CHARCOAL",
                "size": "L",
                "sku": "UT-5000-CHL-L"
            },
            {
                "id": 452,
                "color": "PURPLE",
                "size": "2XL",
                "sku": "UT-5000-PUE-2XL"
            },
            {
                "id": 556,
                "color": "DARK CHOCOLATE",
                "size": "3XL",
                "sku": "UT-5000-DAE-3XL"
            },
            {
                "id": 528,
                "color": "ROYAL",
                "size": "3XL",
                "sku": "UT-5000-ROL-3XL"
            },
            {
                "id": 503,
                "color": "DARK CHOCOLATE",
                "size": "2XL",
                "sku": "UT-5000-DAE-2XL"
            },
            {
                "id": 542,
                "color": "DAISY",
                "size": "3XL",
                "sku": "UT-5000-DAY-3XL"
            },
            {
                "id": 547,
                "color": "HELICONIA",
                "size": "2XL",
                "sku": "UT-5000-HEA-2XL"
            },
            {
                "id": 557,
                "color": "NATURAL",
                "size": "3XL",
                "sku": "UT-5000-NAL-3XL"
            },
            {
                "id": 508,
                "color": "DARK CHOCOLATE",
                "size": "S",
                "sku": "UT-5000-DAE-S"
            },
            {
                "id": 549,
                "color": "ORANGE",
                "size": "S",
                "sku": "UT-5000-ORE-S"
            },
            {
                "id": 533,
                "color": "TENNESSEE ORANGE",
                "size": "L",
                "sku": "UT-5000-TEE-L"
            },
            {
                "id": 532,
                "color": "TENNESSEE ORANGE",
                "size": "M",
                "sku": "UT-5000-TEE-M"
            },
            {
                "id": 513,
                "color": "NATURAL",
                "size": "L",
                "sku": "UT-5000-NAL-L"
            },
            {
                "id": 536,
                "color": "TENNESSEE ORANGE",
                "size": "3XL",
                "sku": "UT-5000-TEE-3XL"
            },
            {
                "id": 534,
                "color": "TENNESSEE ORANGE",
                "size": "XL",
                "sku": "UT-5000-TEE-XL"
            },
            {
                "id": 558,
                "color": "ORANGE",
                "size": "3XL",
                "sku": "UT-5000-ORE-3XL"
            },
            {
                "id": 548,
                "color": "HELICONIA",
                "size": "3XL",
                "sku": "UT-5000-HEA-3XL"
            },
            {
                "id": 531,
                "color": "TENNESSEE ORANGE",
                "size": "S",
                "sku": "UT-5000-TEE-S"
            },
            {
                "id": 535,
                "color": "TENNESSEE ORANGE",
                "size": "2XL",
                "sku": "UT-5000-TEE-2XL"
            },
            {
                "id": 448,
                "color": "NAVY",
                "size": "3XL",
                "sku": "UT-5000-NAY-3XL"
            },
            {
                "id": 520,
                "color": "CHARCOAL",
                "size": "XL",
                "sku": "UT-5000-CHL-XL"
            },
            {
                "id": 483,
                "color": "LIGHT PINK",
                "size": "M",
                "sku": "UT-5000-LIK-M"
            },
            {
                "id": 490,
                "color": "MAROON",
                "size": "L",
                "sku": "UT-5000-MAN-L"
            },
            {
                "id": 491,
                "color": "MAROON",
                "size": "XL",
                "sku": "UT-5000-MAN-XL"
            },
            {
                "id": 524,
                "color": "ROYAL",
                "size": "M",
                "sku": "UT-5000-ROL-M"
            },
            {
                "id": 436,
                "color": "LIGHT BLUE",
                "size": "M",
                "sku": "UT-5000-LIE-M"
            },
            {
                "id": 529,
                "color": "BLACK",
                "size": "5XL",
                "sku": "UT-5000-BLK-5XL"
            },
            {
                "id": 484,
                "color": "LIGHT PINK",
                "size": "L",
                "sku": "UT-5000-LIK-L"
            },
            {
                "id": 523,
                "color": "ROYAL",
                "size": "S",
                "sku": "UT-5000-ROL-S"
            },
            {
                "id": 477,
                "color": "DARK HEATHER",
                "size": "3XL",
                "sku": "UT-5000-DAR-3XL"
            },
            {
                "id": 504,
                "color": "FOREST GREEN",
                "size": "2XL",
                "sku": "UT-5000-FON-2XL"
            },
            {
                "id": 456,
                "color": "RED",
                "size": "L",
                "sku": "UT-5000-RED-L"
            },
            {
                "id": 510,
                "color": "NATURAL",
                "size": "S",
                "sku": "UT-5000-NAL-S"
            },
            {
                "id": 518,
                "color": "CHARCOAL",
                "size": "M",
                "sku": "UT-5000-CHL-M"
            },
            {
                "id": 458,
                "color": "RED",
                "size": "2XL",
                "sku": "UT-5000-RED-2XL"
            },
            {
                "id": 486,
                "color": "LIGHT PINK",
                "size": "2XL",
                "sku": "UT-5000-LIK-2XL"
            },
            {
                "id": 435,
                "color": "LIGHT BLUE",
                "size": "S",
                "sku": "UT-5000-LIE-S"
            },
            {
                "id": 501,
                "color": "BLACK",
                "size": "4XL",
                "sku": "UT-5000-BLK-4XL"
            },
            {
                "id": 527,
                "color": "ROYAL",
                "size": "2XL",
                "sku": "UT-5000-ROL-2XL"
            },
            {
                "id": 517,
                "color": "CHARCOAL",
                "size": "S",
                "sku": "UT-5000-CHL-S"
            },
            {
                "id": 457,
                "color": "RED",
                "size": "XL",
                "sku": "UT-5000-RED-XL"
            },
            {
                "id": 443,
                "color": "NAVY",
                "size": "S",
                "sku": "UT-5000-NAY-S"
            },
            {
                "id": 522,
                "color": "CHARCOAL",
                "size": "3XL",
                "sku": "UT-5000-CHL-3XL"
            },
            {
                "id": 505,
                "color": "FOREST GREEN",
                "size": "3XL",
                "sku": "UT-5000-FON-3XL"
            },
            {
                "id": 446,
                "color": "NAVY",
                "size": "XL",
                "sku": "UT-5000-NAY-XL"
            },
            {
                "id": 553,
                "color": "ORANGE",
                "size": "XL",
                "sku": "UT-5000-ORE-XL"
            },
            {
                "id": 480,
                "color": "ASH",
                "size": "L",
                "sku": "UT-5000-ASH-L"
            },
            {
                "id": 499,
                "color": "SAND",
                "size": "3XL",
                "sku": "UT-5000-SAD-3XL"
            },
            {
                "id": 552,
                "color": "ORANGE",
                "size": "L",
                "sku": "UT-5000-ORE-L"
            },
            {
                "id": 551,
                "color": "ORANGE",
                "size": "M",
                "sku": "UT-5000-ORE-M"
            },
            {
                "id": 470,
                "color": "SPORT GREY",
                "size": "2XL",
                "sku": "UT-5000-SPY-2XL"
            },
            {
                "id": 450,
                "color": "PURPLE",
                "size": "L",
                "sku": "UT-5000-PUE-L"
            },
            {
                "id": 498,
                "color": "SAND",
                "size": "2XL",
                "sku": "UT-5000-SAD-2XL"
            },
            {
                "id": 521,
                "color": "CHARCOAL",
                "size": "2XL",
                "sku": "UT-5000-CHL-2XL"
            },
            {
                "id": 423,
                "color": "MILITARY GREEN",
                "size": "M",
                "sku": "UT-5000-MIN-M"
            },
            {
                "id": 489,
                "color": "MAROON",
                "size": "M",
                "sku": "UT-5000-MAN-M"
            },
            {
                "id": 425,
                "color": "MILITARY GREEN",
                "size": "XL",
                "sku": "UT-5000-MIN-XL"
            },
            {
                "id": 426,
                "color": "MILITARY GREEN",
                "size": "2XL",
                "sku": "UT-5000-MIN-2XL"
            },
            {
                "id": 479,
                "color": "ASH",
                "size": "M",
                "sku": "UT-5000-ASH-M"
            },
            {
                "id": 424,
                "color": "MILITARY GREEN",
                "size": "L",
                "sku": "UT-5000-MIN-L"
            },
            {
                "id": 454,
                "color": "RED",
                "size": "S",
                "sku": "UT-5000-RED-S"
            },
            {
                "id": 449,
                "color": "PURPLE",
                "size": "M",
                "sku": "UT-5000-PUE-M"
            },
            {
                "id": 471,
                "color": "SPORT GREY",
                "size": "3XL",
                "sku": "UT-5000-SPY-3XL"
            },
            {
                "id": 538,
                "color": "DAISY",
                "size": "M",
                "sku": "UT-5000-DAY-M"
            },
            {
                "id": 539,
                "color": "DAISY",
                "size": "L",
                "sku": "UT-5000-DAY-L"
            },
            {
                "id": 422,
                "color": "ASH",
                "size": "2XL",
                "sku": "UT-5000-ASH-2XL"
            },
            {
                "id": 509,
                "color": "FOREST GREEN",
                "size": "S",
                "sku": "UT-5000-FON-S"
            },
            {
                "id": 496,
                "color": "SAND",
                "size": "L",
                "sku": "UT-5000-SAD-L"
            },
            {
                "id": 475,
                "color": "DARK HEATHER",
                "size": "XL",
                "sku": "UT-5000-DAR-XL"
            },
            {
                "id": 482,
                "color": "LIGHT PINK",
                "size": "S",
                "sku": "UT-5000-LIK-S"
            },
            {
                "id": 487,
                "color": "LIGHT PINK",
                "size": "3XL",
                "sku": "UT-5000-LIK-3XL"
            },
            {
                "id": 438,
                "color": "LIGHT BLUE",
                "size": "XL",
                "sku": "UT-5000-LIE-XL"
            },
            {
                "id": 526,
                "color": "ROYAL",
                "size": "XL",
                "sku": "UT-5000-ROL-XL"
            },
            {
                "id": 451,
                "color": "PURPLE",
                "size": "XL",
                "sku": "UT-5000-PUE-XL"
            },
            {
                "id": 540,
                "color": "DAISY",
                "size": "XL",
                "sku": "UT-5000-DAY-XL"
            },
            {
                "id": 537,
                "color": "DAISY",
                "size": "S",
                "sku": "UT-5000-DAY-S"
            },
            {
                "id": 550,
                "color": "NATURAL",
                "size": "M",
                "sku": "UT-5000-NAL-M"
            },
            {
                "id": 544,
                "color": "HELICONIA",
                "size": "M",
                "sku": "UT-5000-HEA-M"
            },
            {
                "id": 441,
                "color": "MILITARY GREEN",
                "size": "S",
                "sku": "UT-5000-MIN-S"
            },
            {
                "id": 543,
                "color": "HELICONIA",
                "size": "S",
                "sku": "UT-5000-HEA-S"
            },
            {
                "id": 463,
                "color": "WHITE",
                "size": "XL",
                "sku": "UT-5000-WHE-XL"
            },
            {
                "id": 485,
                "color": "LIGHT PINK",
                "size": "XL",
                "sku": "UT-5000-LIK-XL"
            },
            {
                "id": 468,
                "color": "SPORT GREY",
                "size": "L",
                "sku": "UT-5000-SPY-L"
            },
            {
                "id": 447,
                "color": "NAVY",
                "size": "2XL",
                "sku": "UT-5000-NAY-2XL"
            },
            {
                "id": 437,
                "color": "LIGHT BLUE",
                "size": "L",
                "sku": "UT-5000-LIE-L"
            },
            {
                "id": 525,
                "color": "ROYAL",
                "size": "L",
                "sku": "UT-5000-ROL-L"
            },
            {
                "id": 481,
                "color": "ASH",
                "size": "XL",
                "sku": "UT-5000-ASH-XL"
            },
            {
                "id": 472,
                "color": "DARK HEATHER",
                "size": "S",
                "sku": "UT-5000-DAR-S"
            },
            {
                "id": 444,
                "color": "NAVY",
                "size": "M",
                "sku": "UT-5000-NAY-M"
            },
            {
                "id": 439,
                "color": "LIGHT BLUE",
                "size": "2XL",
                "sku": "UT-5000-LIE-2XL"
            },
            {
                "id": 440,
                "color": "LIGHT BLUE",
                "size": "3XL",
                "sku": "UT-5000-LIE-3XL"
            },
            {
                "id": 516,
                "color": "NATURAL",
                "size": "XL",
                "sku": "UT-5000-NAL-XL"
            },
            {
                "id": 469,
                "color": "SPORT GREY",
                "size": "XL",
                "sku": "UT-5000-SPY-XL"
            },
            {
                "id": 476,
                "color": "DARK HEATHER",
                "size": "2XL",
                "sku": "UT-5000-DAR-2XL"
            },
            {
                "id": 455,
                "color": "RED",
                "size": "M",
                "sku": "UT-5000-RED-M"
            },
            {
                "id": 478,
                "color": "ASH",
                "size": "S",
                "sku": "UT-5000-ASH-S"
            },
            {
                "id": 467,
                "color": "SPORT GREY",
                "size": "M",
                "sku": "UT-5000-SPY-M"
            },
            {
                "id": 494,
                "color": "SAND",
                "size": "S",
                "sku": "UT-5000-SAD-S"
            },
            {
                "id": 466,
                "color": "SPORT GREY",
                "size": "S",
                "sku": "UT-5000-SPY-S"
            },
            {
                "id": 464,
                "color": "WHITE",
                "size": "2XL",
                "sku": "UT-5000-WHE-2XL"
            },
            {
                "id": 474,
                "color": "DARK HEATHER",
                "size": "L",
                "sku": "UT-5000-DAR-L"
            },
            {
                "id": 512,
                "color": "FOREST GREEN",
                "size": "L",
                "sku": "UT-5000-FON-L"
            },
            {
                "id": 507,
                "color": "FOREST GREEN",
                "size": "M",
                "sku": "UT-5000-FON-M"
            },
            {
                "id": 473,
                "color": "DARK HEATHER",
                "size": "M",
                "sku": "UT-5000-DAR-M"
            },
            {
                "id": 515,
                "color": "FOREST GREEN",
                "size": "XL",
                "sku": "UT-5000-FON-XL"
            },
            {
                "id": 460,
                "color": "WHITE",
                "size": "S",
                "sku": "UT-5000-WHE-S"
            },
            {
                "id": 462,
                "color": "WHITE",
                "size": "L",
                "sku": "UT-5000-WHE-L"
            },
            {
                "id": 433,
                "color": "BLACK",
                "size": "2XL",
                "sku": "UT-5000-BLK-2XL"
            },
            {
                "id": 434,
                "color": "BLACK",
                "size": "3XL",
                "sku": "UT-5000-BLK-3XL"
            },
            {
                "id": 432,
                "color": "BLACK",
                "size": "XL",
                "sku": "UT-5000-BLK-XL"
            },
            {
                "id": 445,
                "color": "NAVY",
                "size": "L",
                "sku": "UT-5000-NAY-L"
            },
            {
                "id": 465,
                "color": "WHITE",
                "size": "3XL",
                "sku": "UT-5000-WHE-3XL"
            },
            {
                "id": 429,
                "color": "BLACK",
                "size": "S",
                "sku": "UT-5000-BLK-S"
            },
            {
                "id": 495,
                "color": "SAND",
                "size": "M",
                "sku": "UT-5000-SAD-M"
            },
            {
                "id": 431,
                "color": "BLACK",
                "size": "L",
                "sku": "UT-5000-BLK-L"
            },
            {
                "id": 461,
                "color": "WHITE",
                "size": "M",
                "sku": "UT-5000-WHE-M"
            },
            {
                "id": 430,
                "color": "BLACK",
                "size": "M",
                "sku": "UT-5000-BLK-M"
            }
        ],
        "name": "Unisex-Gildan-UnisexT-Shirt",
        "sku": "5000"
    },
    {
        "variations": [
            {
                "id": 812,
                "color": "FOREST GREEN",
                "size": "3XL",
                "sku": "SW-18000-FON-3XL"
            },
            {
                "id": 816,
                "color": "CHARCOAL",
                "size": "3XL",
                "sku": "SW-18000-CHL-3XL"
            },
            {
                "id": 811,
                "color": "MILITARY GREEN",
                "size": "3XL",
                "sku": "SW-18000-MIN-3XL"
            },
            {
                "id": 585,
                "color": "MAROON",
                "size": "M",
                "sku": "SW-18000-MAN-M"
            },
            {
                "id": 809,
                "color": "SPORT GREY",
                "size": "3XL",
                "sku": "SW-18000-SPY-3XL"
            },
            {
                "id": 616,
                "color": "LIGHT BLUE",
                "size": "L",
                "sku": "SW-18000-LIE-L"
            },
            {
                "id": 813,
                "color": "DARK HEATHER",
                "size": "3XL",
                "sku": "SW-18000-DAR-3XL"
            },
            {
                "id": 561,
                "color": "SPORT GREY",
                "size": "L",
                "sku": "SW-18000-SPY-L"
            },
            {
                "id": 807,
                "color": "WHITE",
                "size": "3XL",
                "sku": "SW-18000-WHE-3XL"
            },
            {
                "id": 815,
                "color": "MAROON",
                "size": "3XL",
                "sku": "SW-18000-MAN-3XL"
            },
            {
                "id": 583,
                "color": "WHITE",
                "size": "2XL",
                "sku": "SW-18000-WHE-2XL"
            },
            {
                "id": 617,
                "color": "LIGHT BLUE",
                "size": "XL",
                "sku": "SW-18000-LIE-XL"
            },
            {
                "id": 814,
                "color": "NAVY",
                "size": "3XL",
                "sku": "SW-18000-NAY-3XL"
            },
            {
                "id": 817,
                "color": "LIGHT BLUE",
                "size": "3XL",
                "sku": "SW-18000-LIE-3XL"
            },
            {
                "id": 808,
                "color": "SAND",
                "size": "3XL",
                "sku": "SW-18000-SAD-3XL"
            },
            {
                "id": 615,
                "color": "LIGHT BLUE",
                "size": "M",
                "sku": "SW-18000-LIE-M"
            },
            {
                "id": 601,
                "color": "ORANGE",
                "size": "L",
                "sku": "SW-18000-ORE-L"
            },
            {
                "id": 810,
                "color": "ORANGE",
                "size": "3XL",
                "sku": "SW-18000-ORE-3XL"
            },
            {
                "id": 602,
                "color": "ORANGE",
                "size": "XL",
                "sku": "SW-18000-ORE-XL"
            },
            {
                "id": 590,
                "color": "MILITARY GREEN",
                "size": "M",
                "sku": "SW-18000-MIN-M"
            },
            {
                "id": 607,
                "color": "FOREST GREEN",
                "size": "XL",
                "sku": "SW-18000-FON-XL"
            },
            {
                "id": 614,
                "color": "LIGHT BLUE",
                "size": "S",
                "sku": "SW-18000-LIE-S"
            },
            {
                "id": 598,
                "color": "MILITARY GREEN",
                "size": "2XL",
                "sku": "SW-18000-MIN-2XL"
            },
            {
                "id": 588,
                "color": "MAROON",
                "size": "2XL",
                "sku": "SW-18000-MAN-2XL"
            },
            {
                "id": 599,
                "color": "ORANGE",
                "size": "S",
                "sku": "SW-18000-ORE-S"
            },
            {
                "id": 597,
                "color": "MILITARY GREEN",
                "size": "L",
                "sku": "SW-18000-MIN-L"
            },
            {
                "id": 584,
                "color": "MAROON",
                "size": "S",
                "sku": "SW-18000-MAN-S"
            },
            {
                "id": 591,
                "color": "MILITARY GREEN",
                "size": "XL",
                "sku": "SW-18000-MIN-XL"
            },
            {
                "id": 577,
                "color": "NAVY",
                "size": "XL",
                "sku": "SW-18000-NAY-XL"
            },
            {
                "id": 576,
                "color": "NAVY",
                "size": "L",
                "sku": "SW-18000-NAY-L"
            },
            {
                "id": 567,
                "color": "BLACK",
                "size": "XL",
                "sku": "SW-18000-BLK-XL"
            },
            {
                "id": 578,
                "color": "NAVY",
                "size": "2XL",
                "sku": "SW-18000-NAY-2XL"
            },
            {
                "id": 618,
                "color": "LIGHT BLUE",
                "size": "2XL",
                "sku": "SW-18000-LIE-2XL"
            },
            {
                "id": 612,
                "color": "CHARCOAL",
                "size": "XL",
                "sku": "SW-18000-CHL-XL"
            },
            {
                "id": 610,
                "color": "CHARCOAL",
                "size": "M",
                "sku": "SW-18000-CHL-M"
            },
            {
                "id": 572,
                "color": "DARK HEATHER",
                "size": "XL",
                "sku": "SW-18000-DAR-XL"
            },
            {
                "id": 613,
                "color": "CHARCOAL",
                "size": "2XL",
                "sku": "SW-18000-CHL-2XL"
            },
            {
                "id": 611,
                "color": "CHARCOAL",
                "size": "L",
                "sku": "SW-18000-CHL-L"
            },
            {
                "id": 609,
                "color": "CHARCOAL",
                "size": "S",
                "sku": "SW-18000-CHL-S"
            },
            {
                "id": 586,
                "color": "MAROON",
                "size": "L",
                "sku": "SW-18000-MAN-L"
            },
            {
                "id": 562,
                "color": "SPORT GREY",
                "size": "XL",
                "sku": "SW-18000-SPY-XL"
            },
            {
                "id": 571,
                "color": "DARK HEATHER",
                "size": "L",
                "sku": "SW-18000-DAR-L"
            },
            {
                "id": 587,
                "color": "MAROON",
                "size": "XL",
                "sku": "SW-18000-MAN-XL"
            },
            {
                "id": 569,
                "color": "DARK HEATHER",
                "size": "S",
                "sku": "SW-18000-DAR-S"
            },
            {
                "id": 830,
                "color": "RED",
                "size": "L",
                "sku": "SW-18000-RED-L"
            },
            {
                "id": 831,
                "color": "RED",
                "size": "XL",
                "sku": "SW-18000-RED-XL"
            },
            {
                "id": 596,
                "color": "SAND",
                "size": "2XL",
                "sku": "SW-18000-SAD-2XL"
            },
            {
                "id": 605,
                "color": "FOREST GREEN",
                "size": "M",
                "sku": "SW-18000-FON-M"
            },
            {
                "id": 574,
                "color": "NAVY",
                "size": "S",
                "sku": "SW-18000-NAY-S"
            },
            {
                "id": 603,
                "color": "ORANGE",
                "size": "2XL",
                "sku": "SW-18000-ORE-2XL"
            },
            {
                "id": 589,
                "color": "MILITARY GREEN",
                "size": "S",
                "sku": "SW-18000-MIN-S"
            },
            {
                "id": 829,
                "color": "RED",
                "size": "M",
                "sku": "SW-18000-RED-M"
            },
            {
                "id": 832,
                "color": "RED",
                "size": "2XL",
                "sku": "SW-18000-RED-2XL"
            },
            {
                "id": 606,
                "color": "FOREST GREEN",
                "size": "L",
                "sku": "SW-18000-FON-L"
            },
            {
                "id": 573,
                "color": "DARK HEATHER",
                "size": "2XL",
                "sku": "SW-18000-DAR-2XL"
            },
            {
                "id": 592,
                "color": "SAND",
                "size": "S",
                "sku": "SW-18000-SAD-S"
            },
            {
                "id": 563,
                "color": "SPORT GREY",
                "size": "2XL",
                "sku": "SW-18000-SPY-2XL"
            },
            {
                "id": 600,
                "color": "ORANGE",
                "size": "M",
                "sku": "SW-18000-ORE-M"
            },
            {
                "id": 570,
                "color": "DARK HEATHER",
                "size": "M",
                "sku": "SW-18000-DAR-M"
            },
            {
                "id": 579,
                "color": "WHITE",
                "size": "S",
                "sku": "SW-18000-WHE-S"
            },
            {
                "id": 559,
                "color": "SPORT GREY",
                "size": "S",
                "sku": "SW-18000-SPY-S"
            },
            {
                "id": 566,
                "color": "BLACK",
                "size": "L",
                "sku": "SW-18000-BLK-L"
            },
            {
                "id": 806,
                "color": "BLACK",
                "size": "3XL",
                "sku": "SW-18000-BLK-3XL"
            },
            {
                "id": 575,
                "color": "NAVY",
                "size": "M",
                "sku": "SW-18000-NAY-M"
            },
            {
                "id": 595,
                "color": "SAND",
                "size": "XL",
                "sku": "SW-18000-SAD-XL"
            },
            {
                "id": 608,
                "color": "FOREST GREEN",
                "size": "2XL",
                "sku": "SW-18000-FON-2XL"
            },
            {
                "id": 565,
                "color": "BLACK",
                "size": "M",
                "sku": "SW-18000-BLK-M"
            },
            {
                "id": 594,
                "color": "SAND",
                "size": "L",
                "sku": "SW-18000-SAD-L"
            },
            {
                "id": 568,
                "color": "BLACK",
                "size": "2XL",
                "sku": "SW-18000-BLK-2XL"
            },
            {
                "id": 564,
                "color": "BLACK",
                "size": "S",
                "sku": "SW-18000-BLK-S"
            },
            {
                "id": 582,
                "color": "WHITE",
                "size": "XL",
                "sku": "SW-18000-WHE-XL"
            },
            {
                "id": 581,
                "color": "WHITE",
                "size": "L",
                "sku": "SW-18000-WHE-L"
            },
            {
                "id": 580,
                "color": "WHITE",
                "size": "M",
                "sku": "SW-18000-WHE-M"
            },
            {
                "id": 593,
                "color": "SAND",
                "size": "M",
                "sku": "SW-18000-SAD-M"
            },
            {
                "id": 604,
                "color": "FOREST GREEN",
                "size": "S",
                "sku": "SW-18000-FON-S"
            },
            {
                "id": 560,
                "color": "SPORT GREY",
                "size": "M",
                "sku": "SW-18000-SPY-M"
            }
        ],
        "name": "Unisex-Gildan-Sweatshirt",
        "sku": "18000"
    },
    {
        "variations": [
            {
                "id": 639,
                "color": "Sand",
                "size": "S",
                "sku": "HG-18500-SAD-S"
            },
            {
                "id": 828,
                "color": "NAVY",
                "size": "3XL",
                "sku": "HG-18500-NAY-3XL"
            },
            {
                "id": 632,
                "color": "DARK HEATHER",
                "size": "XL",
                "sku": "HG-18500-DAR-XL"
            },
            {
                "id": 630,
                "color": "DARK HEATHER",
                "size": "M",
                "sku": "HG-18500-DAR-M"
            },
            {
                "id": 822,
                "color": "SPORT GREY",
                "size": "3XL",
                "sku": "HG-18500-SPY-3XL"
            },
            {
                "id": 820,
                "color": "SAND",
                "size": "3XL",
                "sku": "HG-18500-SAD-3XL"
            },
            {
                "id": 619,
                "color": "SPORT GREY",
                "size": "S",
                "sku": "HG-18500-SPY-S"
            },
            {
                "id": 631,
                "color": "DARK HEATHER",
                "size": "L",
                "sku": "HG-18500-DAR-L"
            },
            {
                "id": 819,
                "color": "WHITE",
                "size": "3XL",
                "sku": "HG-18500-WHE-3XL"
            },
            {
                "id": 825,
                "color": "NAVY",
                "size": "L",
                "sku": "HG-18500-NAY-L"
            },
            {
                "id": 824,
                "color": "NAVY",
                "size": "M",
                "sku": "HG-18500-NAY-M"
            },
            {
                "id": 821,
                "color": "DARK HEATHER",
                "size": "3XL",
                "sku": "HG-18500-DAR-3XL"
            },
            {
                "id": 827,
                "color": "NAVY",
                "size": "2XL",
                "sku": "HG-18500-NAY-2XL"
            },
            {
                "id": 823,
                "color": "NAVY",
                "size": "S",
                "sku": "HG-18500-NAY-S"
            },
            {
                "id": 826,
                "color": "NAVY",
                "size": "XL",
                "sku": "HG-18500-NAY-XL"
            },
            {
                "id": 629,
                "color": "DARK HEATHER",
                "size": "S",
                "sku": "HG-18500-DAR-S"
            },
            {
                "id": 633,
                "color": "DARK HEATHER",
                "size": "2XL",
                "sku": "HG-18500-DAR-2XL"
            },
            {
                "id": 634,
                "color": "White",
                "size": "S",
                "sku": "HG-18500-WHE-S"
            },
            {
                "id": 622,
                "color": "SPORT GREY",
                "size": "XL",
                "sku": "HG-18500-SPY-XL"
            },
            {
                "id": 642,
                "color": "Sand",
                "size": "XL",
                "sku": "HG-18500-SAD-XL"
            },
            {
                "id": 620,
                "color": "SPORT GREY",
                "size": "M",
                "sku": "HG-18500-SPY-M"
            },
            {
                "id": 640,
                "color": "Sand",
                "size": "M",
                "sku": "HG-18500-SAD-M"
            },
            {
                "id": 636,
                "color": "White",
                "size": "L",
                "sku": "HG-18500-WHE-L"
            },
            {
                "id": 623,
                "color": "SPORT GREY",
                "size": "2XL",
                "sku": "HG-18500-SPY-2XL"
            },
            {
                "id": 635,
                "color": "White",
                "size": "M",
                "sku": "HG-18500-WHE-M"
            },
            {
                "id": 621,
                "color": "SPORT GREY",
                "size": "L",
                "sku": "HG-18500-SPY-L"
            },
            {
                "id": 641,
                "color": "Sand",
                "size": "L",
                "sku": "HG-18500-SAD-L"
            },
            {
                "id": 643,
                "color": "Sand",
                "size": "2XL",
                "sku": "HG-18500-SAD-2XL"
            },
            {
                "id": 637,
                "color": "White",
                "size": "XL",
                "sku": "HG-18500-WHE-XL"
            },
            {
                "id": 818,
                "color": "BLACK",
                "size": "3XL",
                "sku": "HG-18500-BLK-3XL"
            },
            {
                "id": 638,
                "color": "White",
                "size": "2XL",
                "sku": "HG-18500-WHE-2XL"
            },
            {
                "id": 628,
                "color": "Black",
                "size": "2XL",
                "sku": "HG-18500-BLK-2XL"
            },
            {
                "id": 624,
                "color": "Black",
                "size": "S",
                "sku": "HG-18500-BLK-S"
            },
            {
                "id": 627,
                "color": "Black",
                "size": "XL",
                "sku": "HG-18500-BLK-XL"
            },
            {
                "id": 625,
                "color": "Black",
                "size": "M",
                "sku": "HG-18500-BLK-M"
            },
            {
                "id": 626,
                "color": "Black",
                "size": "L",
                "sku": "HG-18500-BLK-L"
            }
        ],
        "name": "Unisex-Gildan-Hoodie",
        "sku": "18500"
    },
    {
        "variations": [
            {
                "id": 750,
                "color": "WHITE",
                "size": "S",
                "sku": "YT-5000B-WHE-S"
            },
            {
                "id": 751,
                "color": "WHITE",
                "size": "M",
                "sku": "YT-5000B-WHE-M"
            },
            {
                "id": 758,
                "color": "RED",
                "size": "S",
                "sku": "YT-5000B-RED-S"
            },
            {
                "id": 752,
                "color": "WHITE",
                "size": "L",
                "sku": "YT-5000B-WHE-L"
            },
            {
                "id": 748,
                "color": "BLACK",
                "size": "L",
                "sku": "YT-5000B-BLK-L"
            },
            {
                "id": 769,
                "color": "DARK HEATHER",
                "size": "XL",
                "sku": "YT-5000B-DAR-XL"
            },
            {
                "id": 755,
                "color": "SAND",
                "size": "M",
                "sku": "YT-5000B-SAD-M"
            },
            {
                "id": 768,
                "color": "DARK HEATHER",
                "size": "L",
                "sku": "YT-5000B-DAR-L"
            },
            {
                "id": 746,
                "color": "BLACK",
                "size": "S",
                "sku": "YT-5000B-BLK-S"
            },
            {
                "id": 754,
                "color": "SAND",
                "size": "S",
                "sku": "YT-5000B-SAD-S"
            },
            {
                "id": 764,
                "color": "SPORT GREY",
                "size": "L",
                "sku": "YT-5000B-SPY-L"
            },
            {
                "id": 766,
                "color": "DARK HEATHER",
                "size": "S",
                "sku": "YT-5000B-DAR-S"
            },
            {
                "id": 759,
                "color": "RED",
                "size": "M",
                "sku": "YT-5000B-RED-M"
            },
            {
                "id": 762,
                "color": "SPORT GREY",
                "size": "S",
                "sku": "YT-5000B-SPY-S"
            },
            {
                "id": 756,
                "color": "SAND",
                "size": "L",
                "sku": "YT-5000B-SAD-L"
            },
            {
                "id": 763,
                "color": "SPORT GREY",
                "size": "M",
                "sku": "YT-5000B-SPY-M"
            },
            {
                "id": 753,
                "color": "WHITE",
                "size": "XL",
                "sku": "YT-5000B-WHE-XL"
            },
            {
                "id": 767,
                "color": "DARK HEATHER",
                "size": "M",
                "sku": "YT-5000B-DAR-M"
            },
            {
                "id": 765,
                "color": "SPORT GREY",
                "size": "XL",
                "sku": "YT-5000B-SPY-XL"
            },
            {
                "id": 757,
                "color": "SAND",
                "size": "XL",
                "sku": "YT-5000B-SAD-XL"
            },
            {
                "id": 760,
                "color": "RED",
                "size": "L",
                "sku": "YT-5000B-RED-L"
            },
            {
                "id": 749,
                "color": "BLACK",
                "size": "XL",
                "sku": "YT-5000B-BLK-XL"
            },
            {
                "id": 761,
                "color": "RED",
                "size": "XL",
                "sku": "YT-5000B-RED-XL"
            },
            {
                "id": 747,
                "color": "BLACK",
                "size": "M",
                "sku": "YT-5000B-BLK-M"
            }
        ],
        "name": "Youth-Gildan-YouthT-Shirt",
        "sku": "5000B"
    },
    {
        "variations": [
            {
                "id": 783,
                "color": "TRUE ROYAL",
                "size": "M",
                "sku": "YT-3001Y-TRL-M"
            },
            {
                "id": 790,
                "color": "PINK",
                "size": "L",
                "sku": "YT-3001Y-PIK-L"
            },
            {
                "id": 782,
                "color": "TRUE ROYAL",
                "size": "S",
                "sku": "YT-3001Y-TRL-S"
            },
            {
                "id": 784,
                "color": "TRUE ROYAL",
                "size": "L",
                "sku": "YT-3001Y-TRL-L"
            },
            {
                "id": 776,
                "color": "WHITE",
                "size": "L",
                "sku": "YT-3001Y-WHE-L"
            },
            {
                "id": 779,
                "color": "NATURAL",
                "size": "M",
                "sku": "YT-3001Y-NAL-M"
            },
            {
                "id": 786,
                "color": "DARK GREY",
                "size": "S",
                "sku": "YT-3001Y-DAY-S"
            },
            {
                "id": 785,
                "color": "TRUE ROYAL",
                "size": "XL",
                "sku": "YT-3001Y-TRL-XL"
            },
            {
                "id": 427,
                "color": "DARK GREY",
                "size": "L",
                "sku": "YT-3001Y-DAY-L"
            },
            {
                "id": 428,
                "color": "DARK GREY",
                "size": "XL",
                "sku": "YT-3001Y-DAY-XL"
            },
            {
                "id": 778,
                "color": "NATURAL",
                "size": "S",
                "sku": "YT-3001Y-NAL-S"
            },
            {
                "id": 781,
                "color": "NATURAL",
                "size": "XL",
                "sku": "YT-3001Y-NAL-XL"
            },
            {
                "id": 775,
                "color": "WHITE",
                "size": "M",
                "sku": "YT-3001Y-WHE-M"
            },
            {
                "id": 780,
                "color": "NATURAL",
                "size": "L",
                "sku": "YT-3001Y-NAL-L"
            },
            {
                "id": 774,
                "color": "WHITE",
                "size": "S",
                "sku": "YT-3001Y-WHE-S"
            },
            {
                "id": 788,
                "color": "PINK",
                "size": "S",
                "sku": "YT-3001Y-PIK-S"
            },
            {
                "id": 777,
                "color": "WHITE",
                "size": "XL",
                "sku": "YT-3001Y-WHE-XL"
            },
            {
                "id": 772,
                "color": "BLACK",
                "size": "L",
                "sku": "YT-3001Y-BLK-L"
            },
            {
                "id": 789,
                "color": "PINK",
                "size": "M",
                "sku": "YT-3001Y-PIK-M"
            },
            {
                "id": 771,
                "color": "BLACK",
                "size": "M",
                "sku": "YT-3001Y-BLK-M"
            },
            {
                "id": 791,
                "color": "PINK",
                "size": "XL",
                "sku": "YT-3001Y-PIK-XL"
            },
            {
                "id": 787,
                "color": "DARK GREY",
                "size": "M",
                "sku": "YT-3001Y-DAY-M"
            },
            {
                "id": 770,
                "color": "BLACK",
                "size": "S",
                "sku": "YT-3001Y-BLK-S"
            },
            {
                "id": 773,
                "color": "BLACK",
                "size": "XL",
                "sku": "YT-3001Y-BLK-XL"
            }
        ],
        "name": "Youth-BellaCanvas-YouthT-Shirt",
        "sku": "3001Y"
    },
    {
        "variations": [
            {
                "id": 793,
                "color": "BLACK",
                "size": "M",
                "sku": "WT-5000L-BLK-M"
            },
            {
                "id": 792,
                "color": "BLACK",
                "size": "S",
                "sku": "WT-5000L-BLK-S"
            },
            {
                "id": 798,
                "color": "WHITE",
                "size": "S",
                "sku": "WT-5000L-WHE-S"
            },
            {
                "id": 799,
                "color": "WHITE",
                "size": "M",
                "sku": "WT-5000L-WHE-M"
            },
            {
                "id": 800,
                "color": "WHITE",
                "size": "L",
                "sku": "WT-5000L-WHE-L"
            },
            {
                "id": 802,
                "color": "WHITE",
                "size": "2XL",
                "sku": "WT-5000L-WHE-2XL"
            },
            {
                "id": 797,
                "color": "BLACK",
                "size": "3XL",
                "sku": "WT-5000L-BLK-3XL"
            },
            {
                "id": 803,
                "color": "WHITE",
                "size": "3XL",
                "sku": "WT-5000L-WHE-3XL"
            },
            {
                "id": 801,
                "color": "WHITE",
                "size": "XL",
                "sku": "WT-5000L-WHE-XL"
            },
            {
                "id": 795,
                "color": "BLACK",
                "size": "XL",
                "sku": "WT-5000L-BLK-XL"
            },
            {
                "id": 796,
                "color": "BLACK",
                "size": "2XL",
                "sku": "WT-5000L-BLK-2XL"
            },
            {
                "id": 794,
                "color": "BLACK",
                "size": "L",
                "sku": "WT-5000L-BLK-L"
            }
        ],
        "name": "Women-Gildan-WomenT-Shirt",
        "sku": "5000L"
    }
]
export default beeful
